<template>
  <PageLayout>
    <template
      v-if="generalInformation"
      #header
    >
      <k-title>
        {{ $tc('invoice.externalInvoices.title', 1) }}: #{{ $route.params.id }}
      </k-title>
    </template>
    <template #flow-actions>
      <v-btn
        tile
        outlined
        color="primary"
        class="mr-2"
        @click="$router.go(-1)"
      >
        {{ $t('actions.back') }}
      </v-btn>
    </template>
    <template
      v-if="invoiceData && generalInformation"
      #default
    >
      <v-row>
        <v-col
          cols="12"
          sm="8"
        >
          <v-sheet class="k-transparent pa-md-8 pl-md-12 pa-4 fill-height">
            <KDisplayGroup class="d-flex flex-column justify-center fill-height">
              <span class="font-weight-bold">{{ generalInformation.receiver.name }}</span>
              <span>{{ $t('invoice.preview.receiver.attn') }} {{ generalInformation.receiver.attn }}</span>
              <AddressDisplay :address="generalInformation.receiver.address" />
            </KDisplayGroup>
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          sm="4"
        >
          <v-sheet class="k-transparent pa-4 fill-height">
            <KDisplayGroup class="d-flex flex-column justify-center text-body-2 fill-height">
              <span>{{ generalInformation.sender.name }}</span>
              <AddressDisplay
                :address="generalInformation.sender.address"
                class="mb-4"
              />
              <div
                v-if="generalInformation.sender.email || generalInformation.sender.phone"
                class="mb-4 d-flex flex-column"
              >
                <span
                  v-if="generalInformation.sender.email"
                >{{ $t('invoice.preview.sender.email') }}: {{ generalInformation.sender.email }}</span>
                <span v-if="generalInformation.sender.phone">{{
                  $t('invoice.preview.sender.phone')
                }}: {{ generalInformation.sender.phone }}</span>
              </div>
              <span>{{
                $t('invoice.preview.sender.bicCode')
              }}: {{ generalInformation.sender.bicCode }}</span>
              <span>{{ $t('invoice.preview.sender.vatNumber') }}:
                {{ generalInformation.sender.vatNumber }}</span>
              <span>{{ $t('invoice.preview.sender.chamberOfCommerceNumber') }}:
                {{ generalInformation.sender.chamberOfCommerceNumber }}</span>
              <span>{{
                $t('invoice.preview.sender.iban')
              }}: {{ generalInformation.sender.iban }}</span>
            </KDisplayGroup>
          </v-sheet>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-sheet class="k-transparent pa-3 fill-height">
            <GeneralInformation
              :values="generalInformation"
              :not-applicable="['invoiceNumberFormat', 'invoiceDate', 'expirationDate']"
            />
          </v-sheet>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <!-- normal invoicelines -->
          <InvoiceTable
            v-if="invoiceData.invoiceLines.length > 0"
            :items="invoiceData.invoiceLines"
            :invoice-type-id="invoiceTypeId"
            :kilometer-allowance="invoiceData.kilometerAllowance"
            :invoice-group-data="invoiceData.groupData"
            :totals="hasCreditInvoiceLines ? null : totals"
            @change="setInvoiceData($route.params.id)"
          />
          <!-- credit invoicelines -->
          <div
            v-if="hasCreditInvoiceLines"
            class="mt-2"
          >
            <InvoiceTable
              v-for="(creditInvoice, idx) in invoiceData.creditInvoices"
              :key="`creditInvoice_${idx}`"
              is-credit-invoice
              show-credit-invoice-header
              :invoice-type-id="invoiceTypeId"
              :items="creditInvoice.invoiceLines"
              :invoice-group-data="creditInvoice.groupData"
              :totals="idx === invoiceData.creditInvoices.length - 1 ? totals : null"
              :original-invoice-number="creditInvoice.originalInvoiceNumber"
              :kilometer-allowance="creditInvoice.kilometerAllowance"
            />
          </div>
        </v-col>
      </v-row>
    </template>
  </PageLayout>
</template>

<script>
import PageLayout from '@/components/layout/PageLayout.vue';
import KTitle from '@/components/layout/KTitle.vue';
import AddressDisplay from '@/modules/address/components/AddressDisplay.vue';
import KDisplayGroup from '@/components/crud/display/KDisplayGroup.vue';
import { external } from '@/modules/invoice/api/index.js';
import { getEnumKeyByValue } from '@/application/helpers/enum.js';
import GeneralInformation from '@/modules/invoice/components/GeneralInformation.vue';
import InvoiceTable from '@/modules/invoice/components/InvoiceTable.vue';

export default {
  name: 'ExternalInvoice',
  components: {
    InvoiceTable,
    GeneralInformation,
    KDisplayGroup,
    AddressDisplay,
    KTitle,
    PageLayout,
  },
  data: () => ({
    invoiceData: null,
    generalInformation: null,
    totals: null,
    invoiceTypeId: 0,
  }),
  computed: {
    hasCreditInvoiceLines() {
      return this.invoiceData?.creditInvoices[0]?.invoiceLines;
    },
  },
  created() {
    this.setInvoiceData(this.$route.params.id);
  },
  methods: {
    async setInvoiceData(id) {
      const results = await external(id);
      this.generalInformation = results.data.data.generalInformation;
      this.invoiceData = results.data.data.invoiceData;
      this.totals = results.data.data.totals;
      this.invoiceTypeId = results.data.data.invoiceTypeId;
    },
    getEnumKeyByValue: getEnumKeyByValue,
  },
};
</script>
